var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Suppliers")]),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchSuppliers.apply(null, arguments)},"click:clear":_vm.clearSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":_vm.create}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New Supplier")])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.suppliers,"options":_vm.options,"server-items-length":_vm.totalSupplier,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.supplier_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('router-link',{staticClass:"font-weight-semibold text-decoration-none",attrs:{"to":{ name: 'supplier-edit', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.supplier_name)+" ")])],1)]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.owner_first_name)+" "+_vm._s(item.owner_last_name))])]}},{key:"item.tel_cel",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.tel_number)),(item.cell_number)?_c('span',[_vm._v("/ "+_vm._s(item.cell_number))]):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]),_c('span',[_vm._v("Edit")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteItemConfirm(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.dialogDelete = !_vm.dialogDelete}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.deleteItem}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }